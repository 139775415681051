import styled from "styled-components";
import { ThreeBars } from "@styled-icons/octicons";

import NavItem from "./NavItem";
import Logo from "./Logo";

export const StyledNavItem = styled(NavItem)`
  white-space: nowrap;
  position: relative;
  padding: 0;
  padding-left 44px;
`;

export const HamburgerIcon = styled(ThreeBars)`
  height: 20px;
  width: 20px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLogo = styled(Logo)`
  font-family: "Como Bold", sans-serif;
  font-weight: 900;
  justify-content: left;
  border-right: 0;
  padding: 0;
  flex: 1;
`;

export const NavWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBgColor};
  color: ${({ theme }) => theme.colors.fgColor};
  height: ${({ theme }) => theme.sizes.navbarHeight};
  padding: 0px;
  display: flex;
  align-items: center;
  padding: 24px 30px;
  top: 0;
  z-index: 1000;
  position: fixed;
  width: calc(100vw - (100vw - 100%));

  @media ${({ theme }) => theme.breakpoints.md} {
    background: ${({ theme }) => theme.colors.bgColor};
    padding: 24px 80px;
  }

  & button {
    margin: 0px 3px 0px 3px;
  }
`;

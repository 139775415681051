import styled from "styled-components";

export const Headline = styled.div`
  color: ${({ theme }) => theme.colors.teal40};
  font-size: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
  line-height: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
  font-weight: 800;
  max-width: 700px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.lg} {
    font-size: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
    line-height: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
    text-align: left;
    margin-top: 60px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    font-size: ${({ theme }) => theme.fontSizes.headline};
    line-height: ${({ theme }) => theme.fontSizes.headline};
    margin-top: 60px;
  }
`;

export const DropHead = styled.div`
  font-size: ${({ theme }) => `calc(${theme.fontSizes.large} * 0.75)`};
  font-weight: 400;
  line-height: 28px;
  max-width: 600px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.lg} {
    font-size: ${({ theme }) => theme.fontSizes.large};
    text-align: left;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.teal40};
  font-size: ${({ theme }) => theme.fontSizes.title};
  white-space: nowrap;
  font-weight: 500;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.teal40};
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
  margin-top: 32px;
  font-weight: 400;
`;

export const Paragraph = styled.p`
  max-width: 600px;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  text-align: center;
  font-weight: 300;
`;

export const Overline = styled.h3`
  color: ${({ theme }) => theme.colors.grey40};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.small};
  font-weight: 900;
  text-transform: uppercase;
`;

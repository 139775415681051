import styled from "styled-components";

export const RoundedImage = styled.img`
  display: block;
  border-radius: ${({ theme }) => theme.radii.x4};
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.6));
  width: 80%;
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: auto;
    width: 100%;
    max-width: 100%;
  }
`;

export const HeroImage = styled.img`
  display: block;
  filter: drop-shadow(0px 10px 10px ${({ theme }) => theme.colors.black});
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: auto;
    margin-right: 0px;
    max-width: 30%;
    max-height: 75%;
  }
`;

export const LogoTypeImage = styled.img`
  display: block;
  filter: drop-shadow(0px 10px 10px ${({ theme }) => theme.colors.black});
  margin-right: auto;
  width: auto;
  height: 37px;
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: auto;
    max-width: 20vw;
    height: 50px;
  }
`;

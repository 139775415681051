import React from "react";

import {
  ColorType,
  Container,
  EqualHStack,
  VerticalStack,
} from "@components/Layout";
import { Title, Paragraph } from "@components/Text";
import { ProfileCard, SOCIAL_ICONS } from "@components/Cards";

const family = [
  {
    imgUrl: "./images/family/uwucrew.png",
    title: "uwucrew",
    socials: [
      {
        url: "https://twitter.com/uwucrewnft",
        icon: SOCIAL_ICONS.TWITTER,
      },
      {
        url: "https://discord.gg/uwucrew",
        icon: SOCIAL_ICONS.DISCORD,
      },
      {
        url: "https://uwucrew.art/",
        icon: SOCIAL_ICONS.WEBSITE,
      },
      {
        url: "https://opensea.io/collection/uwucrew",
        icon: SOCIAL_ICONS.OPENSEA,
      },
    ],
    description:
      "The start of uwu, the foundation of our strong art culture. Community includes over 250 anime artists.",
  },
  {
    imgUrl: "./images/family/personalamps.png",
    title: "Persona Lamps",
    socials: [
      {
        url: "https://twitter.com/persona_lamps",
        icon: SOCIAL_ICONS.TWITTER,
      },
      {
        url: "https://discord.gg/W9dMqtPMaA",
        icon: SOCIAL_ICONS.DISCORD,
      },
      {
        url: "http://personalamps.art/",
        icon: SOCIAL_ICONS.WEBSITE,
      },
      {
        url: "https://opensea.io/collection/persona-lamps",
        icon: SOCIAL_ICONS.OPENSEA,
      },
    ],
    description:
      "Art collection by talented environment artist NIK. Brings together appreciation for landscape and environmental art.",
  },
  {
    imgUrl: "./images/family/killergf.png",
    title: "Killer GF",
    socials: [
      {
        url: "https://twitter.com/killergfnft",
        icon: SOCIAL_ICONS.TWITTER,
      },
      {
        url: "https://killergf.com/",
        icon: SOCIAL_ICONS.WEBSITE,
      },
      {
        url: "https://discord.gg/killergfnft",
        icon: SOCIAL_ICONS.DISCORD,
      },
      {
        url: "https://opensea.io/collection/killergf",
        icon: SOCIAL_ICONS.OPENSEA,
      },
    ],
    description:
      "Badass extension of uwu culture, featuring art by ex-League of Legends Concept Artist Zeronis.",
  },
  {
    imgUrl: "./images/family/aikovirtual.png",
    title: "Aiko Virtual",
    socials: [
      {
        url: "https://twitter.com/aikovirtual",
        icon: SOCIAL_ICONS.TWITTER,
      },
      {
        url: "https://discord.gg/JBU8rcd7BG",
        icon: SOCIAL_ICONS.DISCORD,
      },
      {
        url: "https://aikovirtual.com/",
        icon: SOCIAL_ICONS.WEBSITE,
      },
      {
        url: "https://opensea.io/collection/aikovirtual",
        icon: SOCIAL_ICONS.OPENSEA,
      },
    ],
    description:
      "Heavily fashion-oriented, Aiko is focused on creating elaborate and innovative fashion under their Cyber Cute aesthetic.",
  },
  {
    imgUrl: "./images/family/iroiro.jpeg",
    title: "IROIRO",
    socials: [
      {
        url: "https://twitter.com/IROIRO_NFT",
        icon: SOCIAL_ICONS.TWITTER,
      },      
      {
        url: "https://discord.gg/iroiro",
        icon: SOCIAL_ICONS.DISCORD,
      },
      {
        url: "https://iroiro.world/",
        icon: SOCIAL_ICONS.WEBSITE,
      },  
      {
        url: "https://opensea.io/collection/iroiro",
        icon: SOCIAL_ICONS.OPENSEA,
      },  
    ],
    description:
      "Founded by CyberZ, a Japanese company, IROIRO is focused on building an IP and community around color.",
  },
];

const Family = () => {
  return (
    <Container colorType={ColorType.SECONDARY} id="family">
      <VerticalStack>
        <Title>uwu Labs Family</Title>
        <Paragraph>
          Our family consists of several NFT communities collaborating to spread
          the positive art culture of uwu in diverse ways
        </Paragraph>
        <EqualHStack isWrap={true}>
          {family.map((member) => (
            <ProfileCard
              key={member.title}
              imgUrl={member.imgUrl}
              title={member.title}
              socials={member.socials}
            >
              {member.description}
            </ProfileCard>
          ))}
        </EqualHStack>
      </VerticalStack>
    </Container>
  );
};

export default Family;

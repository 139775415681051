import styled from "styled-components";

import { Twitter, Opensea, Discord } from "@styled-icons/simple-icons";
import { Globe } from "@styled-icons/octicons";

const StyledMirror = styled.img`
  height: 20px;
  transform: translateY(2px);
`

const Mirror = () => {
  return <StyledMirror src="./images/mirror.svg" />
}

export const SOCIAL_ICONS = {
  TWITTER: <Twitter />,
  WEBSITE: <Globe />,
  OPENSEA: <Opensea />,
  DISCORD: <Discord />,
  MIRROR: <Mirror />
};

export interface ProfileCardProps {
  imgUrl: string;
  title: string;
  socials: Social[];
  children: React.ReactNode;
}

type SocialTypes = keyof typeof SOCIAL_ICONS;
type SocialIcons = typeof SOCIAL_ICONS[SocialTypes];

export interface Social {
  url: string;
  icon: SocialIcons;
}

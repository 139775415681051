import { LinkProps, Type } from "./types";
import { StyledLink } from "./styled";

export const Link = ({
  href,
  className,
  linkType = Type.PRIMARY,
  children,
  disabled,
  internal = false,
  ...props
}: LinkProps) => {
  let as: any = "a";
  if (disabled) {
    as = "span";
  }

  return (
    // @ts-ignore
    <StyledLink
      className={className}
      href={href}
      to={href}
      linkType={linkType}
      disabled={disabled}
      as={as}
      target={internal ? "_self" : "_blank"}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export const IconLink = ({ children, ...props }: LinkProps) => (
  <Link {...props} linkType={Type.ICON}>
    {children}
  </Link>
);

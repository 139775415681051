import React from "react";

import {
  ColorType,
  SeparatorType,
  Container,
  ContainerSeparator,
  EqualHStack,
  VerticalStack,
} from "@components/Layout";
import { Title, Subtitle, Paragraph } from "@components/Text";
import { ProfileCard, SOCIAL_ICONS } from "@components/Cards";

const founders = [
  {
    imgUrl: "./images/founders/kiwi.jpeg",
    title: "Kiwi",
    socials: [
      {
        url: "https://twitter.com/0xkiwi_",
        icon: SOCIAL_ICONS.TWITTER,
      },
    ],
    description: "Team & Industry Leader - 5+ years as software engineer in Ethereum industry",
  },
  {
    imgUrl: "./images/founders/laur.jpeg",
    title: "Laur",
    socials: [
      {
        url: "https://twitter.com/fungibleartist",
        icon: SOCIAL_ICONS.TWITTER,
      },
    ],
    description:
      "Concept and product designer with over 6 years of professional experience in anime art, game art, and artist management services",
  },
  {
    imgUrl: "./images/founders/morello.png",
    title: "Morello",
    socials: [
      {
        url: "https://twitter.com/morellostorment",
        icon: SOCIAL_ICONS.TWITTER,
      },
    ],
    description: "Founding member of weeb3dao and top 1/1 collector with experience in partnerships and community management",
  },
];

const About = () => {
  return (
    <Container colorType={ColorType.SECONDARY} id="about">
      <VerticalStack>
        <Title>About</Title>
        <Paragraph>
          uwu Labs is dedicated to helping artists navigate their way through
          Web3 and build honest communities around their art.
        </Paragraph>
        <Paragraph>
          Our expertise has made a large impact on multiple art-focused projects
          through guidance on areas of technical development, art direction,
          community management, and much more.
        </Paragraph>
        <Subtitle>Founders</Subtitle>
        <EqualHStack>
          {founders.map((founder, index) => (
            <ProfileCard
              key={index}
              imgUrl={founder.imgUrl}
              title={founder.title}
              socials={founder.socials}
            >
              {founder.description}
            </ProfileCard>
          ))}
        </EqualHStack>
      </VerticalStack>
      <ContainerSeparator separatorType={SeparatorType.PARTIAL} />
    </Container>
  );
};

export default About;

import styled from "styled-components";
import { Container } from "@components/Layout";

export const ContactContainer = styled(Container)`
  @media ${({ theme }) => theme.breakpoints.md} {
    background-image: url(./images/tapestry.png);
    background-repeat: repeat-y;
    background-size: calc(50% - ${({ theme }) => theme.spacing.x5});
  }
`;

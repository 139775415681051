import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Como Bold';
    src: url('/fonts/como_bold.ttf');
  }
  
  @font-face {
    font-family: 'Fredoka';
    src: url('/fonts/fredoka.ttf');
  }


  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-family: 'Fredoka', sans-serif;
      font-weight: 400;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    padding-top: ${({ theme }) => theme.sizes.navbarHeight};
  }
`;

export default GlobalStyle;

import React from "react";

import { EqualHStack, VerticalStack } from "@components/Layout";
import { Title } from "@components/Text";
import { Button } from "@components/Buttons";

import { ContactContainer } from "./styled";

const Contact = () => {
  return (
    <ContactContainer id="contact">
      <EqualHStack columns={2}>
        <VerticalStack></VerticalStack>
        <VerticalStack align="start">
          <Title>Get in Touch!</Title>
          <Button onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSf0OEth9t3UZdQN-WfUSZn6hkz5TQmSFI--rXN-FH9luaRfEg/viewform", "_blank")}>
            Contact Form
          </Button>
        </VerticalStack>
      </EqualHStack>
    </ContactContainer>
  );
};

export default Contact;

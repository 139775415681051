export enum ColorType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum SeparatorType {
  FULL = "full",
  PARTIAL = "partial",
}

export type ContainerProps = {
  colorType?: ColorType;
};

export type ContainerSeparatorProps = {
  separatorType?: SeparatorType;
};

export type HorizontalStackProps = {
  isWrap?: boolean;
};

export type EqualHStackProps = {
  columns?: number;
};

export type VerticalStackProps = {
  align?: string;
  spacing?: number;
};

import styled from "styled-components";

import NavBar from "@src/components/NavBar";
import { Home, About, Family, Contact, Footer } from "@pages/index";

const StyledApp = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBgColor};
`

function App() {
  return (
    <StyledApp>
      <NavBar />
      <Home />
      <About />
      <Family />
      <Contact />
      <Footer />
    </StyledApp>
  );
}

export default App;

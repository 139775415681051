import styled from "styled-components";

import { VerticalStack } from "@components/Layout";

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.teal40};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.fontSizes.large};
  font-weight: 600;
`;

export const CardParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
  font-weight: 300;
  max-width: 350px;
  line-height: 24px;
`;

export const ProfileCardStack = styled(VerticalStack)`
  img {
    transition: transform 1s;
  }

  &:hover {
    img {
      transform: perspective(500px) translateZ(15px);
    }
  }
`;

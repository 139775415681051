import styled from "styled-components";

import {
  Container,
  ContainerSeparator,
  HorizontalStack,
  HeroVerticalStack,
} from "@components/Layout";
import { Headline, DropHead } from "@components/Text";
import { HeroImage } from "@components/Image";
import { Button } from "@components/Buttons";

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
`

const Home = () => {
  const handleClick = () => {
    window.location.href = "./#contact";
  };

  return (
    <Container id="home">
      <Background src="/images/bg.svg" />
      <HorizontalStack>
        <HeroImage src="./images/hero.png" />
        <HeroVerticalStack align="start">
          <Headline>Empowering Creativity</Headline>
          <DropHead>
            Using NFT art to make a positive impact on anime artists all over the world
          </DropHead>
          <ButtonContainer>
            <Button onClick={handleClick}>Get in Touch</Button>
          </ButtonContainer>
        </HeroVerticalStack>
      </HorizontalStack>
      <ContainerSeparator />
    </Container>
  );
};

export default Home;

export const size = {
  xs: 320,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1440,
  xxl: 2560,
};

export const breakpoints = {
  xs: `(min-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
};

export const maxBreakpoints = {
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
  xl: `(max-width: ${size.xl}px)`,
  xxl: `(max-width: ${size.xxl}px)`,
};

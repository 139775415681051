const baseColors = {
  white: "#f0f0f0",
  black: "#283140",
  transparent: "transparent",

  grey20: "#F9F6F9",
  grey40: "#EEE6ED",
  grey90: "#28313F",

  teal30: "#8298b0",
  teal40: "#afc5d9",

  greyBlue30: "#8ea2b7",
};

const themeColors = {
  ...baseColors,
  primary: baseColors.teal30,
  primaryActive: baseColors.greyBlue30,
  primaryDisabled: baseColors.grey90,

  secondary: baseColors.white,
  secondaryActive: baseColors.white,
  secondaryDisabled: baseColors.white,

  bgColor: baseColors.black,
  secondaryBgColor: baseColors.grey90,

  fgColor: baseColors.grey40,
  secondaryFgColor: baseColors.grey20,

  accent: baseColors.greyBlue30,
};

const colors = {
  ...themeColors,
};

export default colors;

import React from 'react';

import ButtonBase from './ButtonBase';
import { ButtonProps, Type } from './types';

export const SVGButton = ({ children, ...props }: ButtonProps) => (
  <ButtonBase {...props} buttonType={Type.MINIMAL}>
    {children}
  </ButtonBase>
);

export const LinkButton = ({ children, ...props }: ButtonProps) => (
  <ButtonBase {...props} buttonType={Type.LINK}>
    {children}
  </ButtonBase>
);

export const TextButton = ({ children, ...props }: ButtonProps) => (
  <ButtonBase {...props} buttonType={Type.LINK}>
    {children}
  </ButtonBase>
);

export const Button = ({ children, ...props }: ButtonProps) => (
  <ButtonBase {...props} buttonType={Type.PRIMARY}>
    {children}
  </ButtonBase>
);

export const FormButton = ({ children, ...props }: ButtonProps) => (
  <ButtonBase {...props} buttonType={Type.PRIMARY} type="submit">
    {children}
  </ButtonBase>
);

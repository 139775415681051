import styled from "styled-components"

import { ProfileCardProps, Social } from "./types";
import { CardTitle, CardParagraph, ProfileCardStack } from "./styled";

import { RoundedImage } from "@components/Image";
import { IconLink } from "@components/Links";

const SocialContainer = styled.div`
  display: flex;

  > a {
    margin: 0 7px;
  }
`

const ProfileCard = ({
  imgUrl,
  title,
  socials,
  children,
}: ProfileCardProps) => (
  <ProfileCardStack spacing={2}>
    <RoundedImage src={imgUrl} />
    <CardTitle>{title}</CardTitle>
    <SocialContainer >
      {socials.map((social: Social) => (
        <IconLink key={social.url} href={social.url}>
          {social.icon}
        </IconLink>
      ))}
    </SocialContainer >
    <CardParagraph>{children}</CardParagraph>
  </ProfileCardStack>
);

export default ProfileCard;

import { Link } from '@components/Links';

interface IProps {
  url: string;
  className?: string;
  children?: React.ReactNode;
  internal?: boolean;
  onClick?: any;
  disabled?: boolean;
}

const NavItem = ({
  children,
  url,
  className,
  internal,
  onClick,
  disabled,
  ...props
}: IProps) => {
  return (
    <Link
      className={className}
      href={url}
      internal
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Link>
  );
};

export default NavItem;

import styled from "styled-components";

import {
  ColorType,
  SeparatorType,
  ContainerProps,
  ContainerSeparatorProps,
  HorizontalStackProps,
  EqualHStackProps,
  VerticalStackProps,
} from "./types";

export const Container = styled.div<ContainerProps>(
  ({ colorType = ColorType.PRIMARY, theme }) => {
    const breakpointMd = `@media ${theme.breakpoints.md}`;

    return {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      padding: `${theme.sizes.navbarHeight} ${theme.spacing.x4}`,

      overflow: "hidden",
      [breakpointMd]: {
        padding: `${theme.spacing.x12} ${theme.spacing.x10}`,
      },
      ...getColorTypeStyle(colorType, theme),
    };
  }
);

export const ContainerSeparator = styled.div<ContainerSeparatorProps>(
  ({ separatorType = SeparatorType.FULL, theme }) => ({
    position: "absolute",
    bottom: 0,
    height: separatorType === SeparatorType.FULL ? "5px" : "3px",
    background: theme.colors.accent,
    ...getSeparatorTypeStyle(separatorType, theme),
  })
);

export const HorizontalStack = styled.div<HorizontalStackProps>`
  position: relative;
  width: 100%;
  max-width: 1366px;
  gap: ${({ theme }) => theme.spacing.x5};
  display: flex;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-wrap: ${({ isWrap = false }) => (isWrap ? `wrap` : `nowrap`)};
  }
`;

export const EqualHStack = styled(HorizontalStack) <EqualHStackProps>`
  justify-content: center;

  > * {
    width: 100%;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
  > * {
    width: ${({ theme, columns = 3 }) => {
    const numGaps = columns - 1;
    const contentWidth = `100% - (${theme.spacing.x5} * ${numGaps})`;
    return `calc((${contentWidth}) / ${columns})`;
  }}
  }
`;

export const VerticalStack = styled.div<VerticalStackProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: ${({ align = "center" }) => align};
  }

  > *:not(:last-child) {
    margin-bottom: ${({ theme, spacing = 2 }) => theme.spacing[`x${spacing}`]};

    @media ${({ theme }) => theme.breakpoints.md} {
      margin-bottom: ${({ theme, spacing = 2 }) =>
    theme.spacing[`x${spacing}`]};
    }
  }
`;

export const HeroVerticalStack = styled.div<VerticalStackProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: ${({ align = "center" }) => align};
  }

  > *:not(:last-child) {
    margin-bottom: ${({ theme, spacing = 2 }) => theme.spacing[`x${spacing}`]};

    @media ${({ theme }) => theme.breakpoints.md} {
      margin-bottom: ${({ theme, spacing = 2 }) => theme.spacing[`x${spacing}`]};
      margin-right: 200px;
    }
  }
`;

function getColorTypeStyle(colorType: ColorType, theme: any) {
  switch (colorType) {
    case ColorType.PRIMARY:
      return {
        backgroundColor: theme.colors.bgColor,
        color: theme.colors.fgColor,
      };
    case ColorType.SECONDARY:
      return {
        backgroundColor: theme.colors.secondaryBgColor,
        color: theme.colors.secondaryFgColor,
      };
  }
}

function getSeparatorTypeStyle(separatorType: SeparatorType, theme: any) {
  switch (separatorType) {
    case SeparatorType.FULL:
      return {
        width: "100%",
      };
    case SeparatorType.PARTIAL:
      return {
        width: "33%",
      };
  }
}
